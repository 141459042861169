/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

@import './assets/styles/variables.css';
// regular style toast
@import 'ngx-toastr/toastr';

html, body { height: 100%;}
body {
  font-family: 'Open Sans', sans-serif !important
}
.content-container {
    padding-left: 8rem; /* Ajusta este valor para alinear con el header */
  }
  
  .form-container {
    margin-top: 20px; /* Espacio entre el encabezado y el formulario */
  }

  .mat-form-field-appearance-outline .mat-form-field-outline {
    height: 90px !important;
}

body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

::ng-deep .mat-form-field {
  .mat-input-element {
      color: slategray;
  }
  .mat-form-field-label {
      color: slategray;
  }
  .mat-form-field-underline {
      background-color: slategray;
  }
  .mat-form-field-ripple {
      background-color: slategray;
  }
  .mat-form-field-required-marker {
      color: slategray;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}